import * as types from "./types";

const INITIAL_STATE = {
  legal_services_list: {},
  legal_services_category: [],
  legal_services_status_count: [],
  legal_services_details: {},
};

export default function LegalServices(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_LEGAL_SERVICES_LIST:
      return {
        ...state, legal_services_list: action.payload,
      };
    case types.GET_LEGAL_SERVICES_CATEGORY:
      return { 
        ...state, legal_services_category: action.payload,
      };
    case types.GET_LEGAL_SERVICES_STATUS_COUNT:
      return {
        ...state, legal_services_status_count: action.payload,
      };
      case types.GET_LEGAL_SERVICE_DETAILS:
        return {
          ...state, legal_services_details: action.payload,
        };
    default:
      return state;
  }
}
