import React, { Suspense, lazy } from "react";
import { Router, Switch } from "react-router-dom";
import Loader from "../components/Loader";
import history from "./History";
import {
  Contracts, landingPage, FreeTrail, Thanks,
  TermsAndConditions,
  Login,
  WhoWEAre,
  WhatAboutMustand,
  CommonQuestions,
  Blogs,
  ContactUs,
  PrivacyPolicy,
  Blog1,
  Blog2,
  Blog3,
  Blog4,
  VerifyPhone,
  SetPassword,
  ConfirmResetPassword,
  EmailSendResetPassword,
  ResetPassword,
  UploadLogo,
  PrintTable,
  Cases,
  Client,
  BlankPage,
  AddPhoneNumber,
  AddOtp,
  AddPassword,
  AddIdentification,
  CompleteProfile,
} from "../utils/LazyLoaded";
import LandingPage from '../containers/LandingPage'
import Auth from "../utils/Auth";
import SEO from "../components/SEO";

const Navbar = lazy(() => import("../components/Layout/Navbar")) ;
const Footer = lazy(() => import("../components/Layout/Footer"));
const PrivateRoute = lazy(() => import("../routes/guardedRoutes/PrivateRoute"));
const PublicRoute = lazy(() => import("../routes/guardedRoutes/PublicRoute"));
const LandingPageRoute = lazy(() => import("../routes/guardedRoutes/LandingPageRoute"));
const FirmUsersRoute = lazy(() => import("../routes/guardedRoutes/FirmUsersRoute"));
const ContractsRoute = lazy(() => import("./guardedRoutes/ContractsRoute"));

const Routes = ({ routings, children }) => {
  return (
    <Suspense fallback={<Loader />}>
      {children && children}
      <Router history={history}>
        <div className="rootApp">
          {Auth.isAuth() && !history.location.pathname.includes("/contracts") &&
            history.location.pathname !== "/" &&
            history.location.pathname !== "/printTable" && <Navbar />}
          <Switch>
            <ContractsRoute
              path="/contracts/list"
              component={Contracts}
              exact
            />
            <ContractsRoute
              path="/contracts"
              component={landingPage}
              exact
              hideHeader
            />
            <ContractsRoute
              path="/contracts/التجربة-المجانية"
              component={FreeTrail}
              hideHeader
            />
            <ContractsRoute
              path="/contracts/thanks"
              component={Thanks}
              exact
              hideHeader
            />
            <ContractsRoute
              path="/contracts/الشروط-والأحكام"
              component={TermsAndConditions}
              exact
              hideHeader
            />
             <ContractsRoute
              path="/contracts/من-نحن"
              component={WhoWEAre}
              hideHeader
            />
              <ContractsRoute
              path="/contracts/ما-هو-مستند"
              component={WhatAboutMustand}
              hideHeader
            />
              <ContractsRoute
              path="/contracts/الأسئلة-الشائعة"
              component={CommonQuestions}
              hideHeader
            />
              <ContractsRoute
              path="/contracts/المدونة"
              component={Blogs}
              hideHeader
            />
              <ContractsRoute
              path="/contracts/تواصل-معنا"
              component={ContactUs}
              hideHeader
            />
               <ContractsRoute
              path="/contracts/دليل-أفضل-نظام-إدارة-القضايا"
              component={Blog1}
              hideHeader
            />
               <ContractsRoute
              path="/contracts/إدارة-العملاء-بكفاءة-دليل-شامل-للمحامين-وأصحاب-المكاتب"
              component={Blog2}
              hideHeader
            />
               <ContractsRoute
              path="/contracts/فوائد-أتمتة-ممارستك-القانونية-مع-مستند"
              component={Blog3}
              hideHeader
            />
              <ContractsRoute
              path="/contracts/فوائد-الحلول-السحابية-في-أرشفة-المستندات-القانونية"
              component={Blog4}
              hideHeader
            />
            
            <PublicRoute path="/login" component={Login} exact />
            <LandingPageRoute
              path={"/"}
              component={LandingPage}
              exact
              noHeader={true}
              seo={<SEO id={1} />}
            />

            <LandingPageRoute
              path="/من-نحن"
              component={WhoWEAre}
              exact
              noHeader={true}
              seo={<SEO id={2} />}
            />
            <LandingPageRoute
              path="/ما-هو-مستند"
              component={WhatAboutMustand}
              exact
              noHeader={true}
              seo={<SEO id={3} />}
            />
            <LandingPageRoute
              path="/الأسئلة-الشائعة"
              component={CommonQuestions}
              exact
              noHeader={true}
              seo={<SEO id={4} />}
            />
            <LandingPageRoute
              path="/المدونة"
              component={Blogs}
              exact
              noHeader={true}
              seo={<SEO id={5} />}
            />
            <LandingPageRoute
              path="/تواصل-معنا"
              component={ContactUs}
              exact
              noHeader={true}
              seo={<SEO id={6} />}
            />
            <LandingPageRoute
              path="/سياسة-الخصوصية"
              component={PrivacyPolicy}
              exact
              noHeader={true}
              seo={<SEO id={7} />}
            />
            <LandingPageRoute
              path="/الشروط-والأحكام"
              component={TermsAndConditions}
              exact
              noHeader={true}
              seo={<SEO id={8} />}
            />
            <LandingPageRoute
              path="/التجربة-المجانية"
              component={FreeTrail}
              noHeader={true}
              seo={<SEO id={9} />}
            />
            <LandingPageRoute
              path="/thanks"
              component={Thanks}
              exact
              noHeader={true}
              seo={<SEO id={9} />}
            />
            <LandingPageRoute
              path="/دليل-أفضل-نظام-إدارة-القضايا"
              component={Blog1}
              exact
              noHeader={true}
              seo={<SEO id={10} />}
            />
            <LandingPageRoute
              path="/إدارة-العملاء-بكفاءة-دليل-شامل-للمحامين-وأصحاب-المكاتب"
              component={Blog2}
              exact
              noHeader={true}
              seo={<SEO id={11} />}
            />

             <LandingPageRoute
              path="/فوائد-أتمتة-ممارستك-القانونية-مع-مستند"
              component={Blog3}
              exact
              noHeader={true}
              seo={<SEO id={12} />}
            />

<           LandingPageRoute
              path="/فوائد-الحلول-السحابية-في-أرشفة-المستندات-القانونية"
              component={Blog4}
              exact
              noHeader={true}
              seo={<SEO id={13} />}
            />

            <PublicRoute
              path="/verify"
              component={VerifyPhone}
              exact
              newHeader={true}
            />
            <PublicRoute
              path="/setPassword"
              component={SetPassword}
              exact
            />
            <PublicRoute
              path="/confirmResetPassword"
              component={ConfirmResetPassword}
              exact
            />
            <PublicRoute
              path="/emailSend"
              component={EmailSendResetPassword}
              exact
            />
            <PublicRoute
              path="/api/shared/validate-signature/:id"
              component={ResetPassword}
              exact
            />
            <PrivateRoute
              path="/uploadLogo"
              component={UploadLogo}
              layout="auth"
              exact
            />
            <PrivateRoute
              path="/printTable"
              component={PrintTable}
              exact
            />
            <FirmUsersRoute
              path="/cases"
              component={Cases}
              exact
            />
            <PublicRoute
              path="/client"
              component={Client}
              exact
              noHeader={true}
            />
            <PublicRoute
              path="/cases/blankPage"
              component={BlankPage}
              exact
              noHeader={true}
            />
            <PublicRoute
              path="/contracts/addPhoneNumber"
              component={AddPhoneNumber}
              exact
              noHeader={true}
            />
            <PublicRoute
              path="/contracts/addOtp"
              component={AddOtp}
              exact
              noHeader={true}
            />
            <PublicRoute
              path="/contracts/addPassword"
              component={AddPassword}
              exact
              noHeader={true}
            />
            <PublicRoute
              path="/contracts/addIdentification"
              component={AddIdentification}
              exact
              noHeader={true}
            />
            <PublicRoute
              path="/contracts/completeProfile"
              component={CompleteProfile}
              exact
              noHeader={true}
            />
            {routings && routings.length
              ? routings.map((route, index) => {
                const Route = route.kind;
                return <Route key={index} {...route} exact />;
              })
              : null}
          </Switch>
          {/* {Auth.isAuth() &&
            history.location.pathname !== "/" &&
            history.location.pathname !== "/printTable" &&
            !history.location.pathname.includes("/cases") &&
            !history.location.pathname.includes("/invoices") &&
            !history.location.pathname.includes("/clients") &&
            !history.location.pathname.includes("/sheetInstructions") &&
            !history.location.pathname.includes("/contracts") &&
            <Footer />} */}
        </div>
      </Router>
    </Suspense>
  );
};

export default Routes;
