import * as types from "./types";

const INITIAL_STATE = {
  contracts_status_count: [],
  contracts_list: {},
  draft_contracts_list: [],
  contract_status: [],
  contract_type: [],
  contract_category: [],
  car_brand: [],
  vehicle_license: [],
  identification_types: [],
  company_types: [],
  search_by_identification_result: null,
  search_by_email_result: null,
  search_by_phone_number_result: null,
};

export default function Contracts(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_CONTRACTS_STATUS_COUNT:
      return {
        ...state, contracts_status_count: action.payload
      };
    case types.GET_CONTRACTS_LIST:
      return {
        ...state, contracts_list: action.payload
      };
      case types.GET_DRAFT_CONTRACTS_LIST:
        return {
          ...state, draft_contracts_list: action.payload
        };
    case types.GET_CONTRACT_STATUS:
      return {
        ...state, contract_status: action.payload
      };
    case types.CONTRACT_TYPE:
      return {
        ...state, contract_type: action.payload
      };
    case types.CONTRACT_CATEGORY:
      return {
        ...state, contract_category: action.payload
      };
    case types.CAR_BRAND:
      return {
        ...state, car_brand: action.payload
      };
    case types.VEHICLE_LICENSE:
      return {
        ...state, vehicle_license: action.payload
      };
    case types.GET_IDENTIFICATION_TYPES:
      return {
        ...state, identification_types: action.payload
      };
      case types.GET_COMPANY_TYPES:
        return {
          ...state, company_types: action.payload
        };
    case types.SEARCH_BY_IDENTIFICATION:
      return {
        ...state, search_by_identification_result: action.payload
      };
      case types.SEARCH_BY_EMAIL:
      return {
        ...state, search_by_email_result: action.payload
      };
      case types.SEARCH_BY_PHONE_NUMBER:
        return {
          ...state, search_by_phone_number_result: action.payload
        };
    default:
      return state;
  }
}
