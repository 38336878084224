export const GET_LEGAL_SERVICES_LIST_REQUEST = "GET_LEGAL_SERVICES_LIST_REQUEST";
export const GET_LEGAL_SERVICES_LIST = "GET_LEGAL_SERVICES_LIST";

export const GET_LEGAL_SERVICES_CATEGORY_REQUEST = "GET_LEGAL_SERVICES_CATEGORY_REQUEST";
export const GET_LEGAL_SERVICES_CATEGORY = "GET_LEGAL_SERVICES_CATEGORY";

export const GET_LEGAL_SERVICES_STATUS_COUNT_REQUEST = "GET_LEGAL_SERVICES_STATUS_COUNT_REQUEST";
export const GET_LEGAL_SERVICES_STATUS_COUNT = "GET_LEGAL_SERVICES_STATUS_COUNT";

export const EXPORT_LEGAL_SERVICES_LIST_REQUEST = "EXPORT_LEGAL_SERVICES_LIST_REQUEST";

export const ADD_EDIT_LEGAL_SERVICE_REQUEST = "ADD_EDIT_LEGAL_SERVICE_REQUEST";

export const GET_LEGAL_SERVICE_DETAILS_REQUEST = "GET_LEGAL_SERVICE_DETAILS_REQUEST";
export const GET_LEGAL_SERVICE_DETAILS = "GET_LEGAL_SERVICE_DETAILS";