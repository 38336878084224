import * as LazyComponent from "../../utils/LazyLoaded";
import FirmUsersRoute from "./FirmUsersRoute";

export const LegalServicesPath = [
    {
        kind: FirmUsersRoute,
        path: "/legal_services",
        component: LazyComponent.LegalServices,
      },
      {
        kind: FirmUsersRoute,
        path: "/legal_services/add",
        component: LazyComponent.AddEditService,
      },
      {
        kind: FirmUsersRoute,
        path: "/legal_services/edit/:id",
        component: LazyComponent.AddEditService,
      },
      {
        kind: FirmUsersRoute,
        path: "/legal_services/:id",
        component: LazyComponent.ServiceDetails,
      },
]
